import { body } from "express-validator/check";
import React, {useCallback, useState, useEffect} from "react";
import ErrorBoundary from './ErrorBoundary'
import galleryImages from "./images/gallery/**/*.jpg";

export default function Gallery(props) {
    const data = require('./works.json');
    const [currentProject, handleLightboxClick] = useState(null);
    let currentProjectData = null;

    useEffect(() => {
        const bodyNode = document.querySelector('.main');
        if (currentProjectData !== null) {
            bodyNode.classList.add('lightbox-height');
        } else {
            bodyNode.classList.remove('lightbox-height');
        }
    });
    
    function getHeroImage(projectName) {
        const imageFolder = galleryImages[projectName];
        if (imageFolder) {
            return imageFolder[Object.keys(imageFolder)[0]];
        }
    }

    function getGalleryImage(projectName) {
        const imageFolder = galleryImages[projectName];
        return Object.values(imageFolder).map((url, imageIndex) => (
            imageIndex > 0 ? <img src={url} key={imageIndex}></img> : null
        ))
    }

    function getLightboxContent() {
        if (currentProject !== null) {
            data.map(data => {
                if (data.name == currentProject) {
                    currentProjectData = data;
                }
            })
            return (<ErrorBoundary>
                <section className="lightbox-container">
                    <a href="#" className="lightbox-close" onClick={e => { e.preventDefault(); handleLightboxClick(null) }}>
                        &laquo; BACK
                </a>
                    <div className="lightbox-project">
                        <div className="lightbox-description">
                            <h3>{currentProjectData.title}</h3>
                            {currentProjectData.description.split('\n').map((c, pIndex) => {
                                return (<p key={pIndex}>{c}</p>)
                            })}
                            <div className="lightbox-tools">{
                                currentProjectData.tools.map((tools, subindex) =>
                                    <span key={subindex}>{tools.tool_name}</span>
                                )
                            }</div>
                        </div>
                        <div className="lightbox-image">
                            {getGalleryImage(currentProjectData.name)}
                            {currentProjectData.youtube ? <div dangerouslySetInnerHTML={{__html: currentProjectData.youtube}}></div> : null}  
                            {currentProjectData.vimeo ? <div className="video-container" dangerouslySetInnerHTML={{__html: currentProjectData.vimeo}}></div> : null}
                        </div>
                    </div>
                </section>
            </ErrorBoundary>);
        } else {
            return null;
        }
    }

    return (<ErrorBoundary>
            {data.map((data, index) => 
                <a href="#" onClick={e => { e.preventDefault(); handleLightboxClick(data.name) }} key={index} className="project-container">
                    <div className="project-description">
                        <h3>{data.title}</h3>
                        <p>{data.employer}</p>
                        <div className="project-tools">{
                            data.tools.map((tools, subindex) =>
                                <span key={subindex}>{tools.tool_name}</span>
                            )
                            }</div>
                    </div>
                    <div className="project-image">
                        <img src={getHeroImage(data.name)}></img>
                    </div>
                </a>
        )}
        {getLightboxContent()}
        </ErrorBoundary>
    );
}

