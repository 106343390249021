import React from "react";
import ReactDOM from "react-dom";
import Gallery from "./Gallery";

import "./stylesheets/all.scss";

const galleryNode = document.getElementById("works-container");

// moreAbout();
ReactDOM.render(<Gallery />, galleryNode);


